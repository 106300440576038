// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-docs-js": () => import("/home/lacker/axiom.org/src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-index-js": () => import("/home/lacker/axiom.org/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("/home/lacker/axiom.org/src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/lacker/axiom.org/.cache/data.json")

